<template>
  <div>
    <Echart
      :options="options"
      id="bottomLineChart"
      height="144px"
      width="460px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
      // 定义颜色
      colorList: [
        {
          itemStyle: "#317EEA",
          linearBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#317EEA",
              },
              {
                offset: 1,
                color: "#0D2B44",
              },
            ],
          },
          areaBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(49, 126, 234, 0.5)",
              },
              {
                offset: 1,
                color: "rgba(49, 126, 234, 0)",
              },
            ],
          },
        },
        {
          itemStyle: "#42F4B7",
          linearBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#42F4B7",
              },
              {
                offset: 1,
                color: "#30DCE8",
              },
            ],
          },
          areaBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(11,244,66, 0.5)",
              },
              {
                offset: 1,
                color: "rgba(11,244,66, 0)",
              },
            ],
          },
        },
        {
          itemStyle: "#FBDD76",
          linearBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "#FBDD76",
              },
              {
                offset: 1,
                color: "#FC915A",
              },
            ],
          },
          areaBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(255,187,118,0.5)",
              },
              {
                offset: 1,
                color: "rgba(255,187,118,0)",
              },
            ],
          },
        },
      ],
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: "item",
          },

          grid: {
            left: 40,
            right: 16,
            bottom: 20,
            top: 50,
          },
          // dataZoom: [
          //   {
          //     // 这里配置dataZoom组件
          //     type: "slider", // 这里是滑动条类型
          //     start: 0, // 数据窗口范围的起始百分比
          //     end: 100, // 数据窗口范围的结束百分比
          //     showDetail: true,
          //    handleSize: "80%",
          //     handleStyle: {
          //       color: "#fff",
          //       shadowBlur: 3,
          //       shadowColor: "rgba(0, 0, 0, 0.6)",
          //       shadowOffsetX: 2,
          //       shadowOffsetY: 2,
          //     },
          //   },
          // ],
          xAxis: {
            type: "category",
            position: "bottom",
            axisLine: true,
            axisLabel: {
              color: "#fff",
              fontSize: 12,
              // interval: 200, // 每隔3个类目显示一个标签
              // fontWeight: 600,
              formatter: function (params) {

                return params.substring(10,16)
              },
            },
            data: this.CalculateAveragePoints(newData.x),
          },
          // 下方Y轴
          yAxis: {
            nameLocation: "end",
            nameGap: 24,
            nameTextStyle: {
              color: "#fff",
              fontSize: 14,
            },
            // max: 100,
            // splitNumber: 5,

            axisLine: {
              lineStyle: {
                opacity: 0,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#fff",
                opacity: 0.1,
              },
            },
            axisLabel: {
              color: "#fff",
              fontSize: 12,
              // formatter: "{value} %",
            },
          },
          legend: {
            data: ["CPU", "MEM", "GPU"],
            right: 16,
            top: 10,
            textStyle: {
              fontSize: 12,
              color: (params) => {
                // 假设你的series数组中存储了颜色信息
                const series = this.options.series.find(
                  (s) => s.name === params.name
                );
                return series ? series.lineStyle.color : "red";
              },
            },
            icon: "roundRect", // 图例icon
            itemGap: 16,
            itemWidth: 8,
            itemHeight: 3,
          },

          series: [
            {
              name: "CPU",
              type: "line",
              smooth: true,
              symbol: "emptyCircle",
              symbolSize: 5,
              itemStyle: {
                normal: {
                  color: this.colorList[0].itemStyle,
                },
              },
              lineStyle: {
                normal: {
                  color: this.colorList[0].linearBtoG,
                  width: 1,
                },
              },
              areaStyle: {
                normal: {
                  color: this.colorList[0].areaBtoG,
                },
              },
              data: newData.cpu,
              lineSmooth: true,

              tooltip: {
                position: "top",
                // formatter: "{c} m",
                backgroundColor: "rgba(28,152,232,.2)",
                padding: 6,
              },
            },

            {
              name: "MEM",
              type: "line",
              smooth: true,
              symbol: "emptyCircle",
              symbolSize: 5,
              itemStyle: {
                normal: {
                  color: this.colorList[1].itemStyle,
                },
              },
              lineStyle: {
                normal: {
                  color: this.colorList[1].linearBtoG,
                  width: 1,
                },
              },
              areaStyle: {
                normal: {
                  color: this.colorList[1].areaBtoG,
                },
              },
              data: newData.mem,
              lineSmooth: true,

              tooltip: {
                position: "top",
                // formatter: "{c} m",
                backgroundColor: "rgba(28,152,232,.2)",
                padding: 6,
              },
            },
            {
              name: "GPU",
              type: "line",
              smooth: true,
              symbol: "emptyCircle",
              symbolSize: 5,
              itemStyle: {
                normal: {
                  color: this.colorList[2].itemStyle,
                },
              },
              lineStyle: {
                normal: {
                  color: this.colorList[2].linearBtoG,
                  width: 1,
                },
              },
              areaStyle: {
                normal: {
                  color: this.colorList[2].areaBtoG,
                },
              },
              data: newData.gpu,

              lineSmooth: true,

              tooltip: {
                position: "top",
                // formatter: "{c} m",
                backgroundColor: "rgba(28,152,232,.2)",
                padding: 1,
              },
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
