<template>
  <div class="d-flex jc-center">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
import { getHistoricalUtilisation} from "@/common/api/index.js";

export default {
  data() {
    return {
      drawTiming: null,
      cdata: {
        x: [],
        gpu: [],
        cpu: [],
        mem: [],
      },
    };
  },
  components: {
    Chart,
  },
  props: {
    cluster: {
      type: String,
      default: () => "",
    },
  },
  mounted() {
    this.drawTimingFn();
  },
  beforeDestroy() {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn() {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, this.$requestInterval);
    },
    setData() {
      // 获取历史利用率
      const params = {
        cluster: this.cluster,
      };
      getHistoricalUtilisation(params)
        .then((response) => {
          const { x, cpu, gpu, mem } = response.data;
          this.cdata.x = x;
          this.cdata.gpu = cpu;
          this.cdata.cpu = gpu;
          this.cdata.mem = mem;
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>