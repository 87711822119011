<template>
  <div id="bottomOne">
    <div class="bg-color-black">
      <!-- 头部标题 -->
      <div class="header">
        <div class="left">{{ title }}</div>
        <div class="right d-flex">
          <div class="right-item" @click="JumpDetail">集群详情</div>
          <div class="right-item">前往使用</div>
        </div>
      </div>

      <!-- 实时利用率 水波图 -->
      <div class="water-echart">
        <div class="h2-title">
          <div class="play-icon"></div>
          <span>实时使用率</span>
        </div>
        <div class="water-list">
          <div class="item" v-for="item in waterEchartData" :key="item.name">
            <dv-water-level-pond
              ref="water"
              :config="item"
              style="width: 80px; height: 80px"
            />
            <!-- 数值 -->
            <div class="value">{{ item.name }} {{ item.value }}</div>

          </div>
        </div>
      </div>

      <!-- 历史使用率折线图 -->
      <div class="line-echart">
        <div class="h2-title">
          <div class="play-icon"></div>
          <span>历史使用率</span>
        </div>
        <div class="d-flex jc-center">
          <BottomLineChart :cluster="cluster"/>
        </div>
      </div>

      <!-- 节点状态 堆叠柱状图 -->
      <div class="bar-echart">
        <div class="h2-title">
          <div class="play-icon"></div>
          <span>节点状态</span>
        </div>
        <div class="d-flex jc-center">
          <BottomBarChart :cluster="cluster"/>
        </div>
      </div>

      <!-- 任务状态 折线柱状图 -->
      <div class="line-bar-echart">
        <div class="h2-title">
          <div class="play-icon"></div>
          <span>任务状态</span>
        </div>
        <div class="d-flex jc-center">
          <BottomLineBarChart :cluster="cluster"/>
        </div>
      </div>

      
      <!-- 轮播表 -->
      <div class="scroll-box d-flex jc-between">
        <!-- 任务 -->
        <dv-scroll-board v-if="taskConfig.data.length>0"
          :config="taskConfig"
          style="width: 208px; height: 138px"
        />
        <!-- 节点 -->
        <dv-scroll-board v-if="nodeConfig.data.length>0"
          :config="nodeConfig"
          style="width: 208px; height: 138px"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
// 折线图
import BottomLineChart from "@/components/echart/bottom/bottomLineChart";

// 柱状图
import BottomBarChart from "@/components/echart/bottom/bottomBarChart";

// 折线柱状图
import BottomLineBarChart from "@/components/echart/bottom/bottomLineBarChart";


import {
  getRealTimeUtilisation,
  getNodeIndex,
  getTaskIndex,
} from "@/common/api/index.js";

export default {
  components: {
    BottomLineChart,
    BottomBarChart,
    BottomLineBarChart,
  },

  data() {
    return {
      cluster: "AT",
      title:'大气科学集群',
      taskConfig: {
        header: ["任务ID", "任务名", "用户名","运行时长"], // 表头title
        data: [],
        headerBGC: "rgba(106, 112, 124, 0.20)", //表头颜色
        evenRowBGC: "", //偶数列背景色
        oddRowBGC: "rgba(106, 112, 124, 0.20)", // 基数列背景色
         columnWidth: [46.6,46.6,46.6,68], // 每列宽度
        align:['center'],
        waitTime:'3000', // 轮播间隔
        rowNum:4, //展示的行数
      },

      nodeConfig: {
        header: ["节点名称", "状态", "CPU","MEM"], // 表头title
        data: [],
        headerBGC: "rgba(106, 112, 124, 0.20)", //表头颜色
        evenRowBGC: "", //偶数列背景色
        oddRowBGC: "rgba(106, 112, 124, 0.20)", // 基数列背景色
         columnWidth: [68, 46.6,46.6,46.6], // 每列宽度
        align:['center'],
        waitTime:'3000', // 轮播间隔
        rowNum:4, //展示的行数
      },
      waterEchartData: [
        {
          name: "CPU",
          data: [42],
          shape: "roundRect",
          colors: ["rgba(49, 126, 234, 1)", "rgba(48, 209, 238, 1)"],
          waveNum: 1,
          waveHeight: 10,
          waveOpacity: 0.3,
          unit: "核",
          value: 32,
          total: 64,
        },
        {
          name: "MEM",
          data: [67],
          shape: "roundRect",
          colors: ["rgba(66, 244, 183, 1)", "rgba(48, 220, 232, 1)"],
          waveNum: 1,
          waveHeight: 10,
          waveOpacity: 0.3,
          unit: "GB",
          value: 32,
          total: 64,
        },
        {
          name: "GPU",
          data: [56],
          shape: "roundRect",
          colors: ["rgba(251, 221, 118, 1)", "rgba(252, 145, 90, 1)"],
          waveNum: 1,
          waveHeight: 10,
          waveOpacity: 0.3,
          unit: "卡",
          value: 32,
          total: 64,
        },
        {
          name: "存储",
          data: [42],
          shape: "roundRect",
          colors: ["rgba(218, 34, 255, 1)", "rgba(151, 51, 238, 1)"],
          waveNum: 1,
          waveHeight: 10,
          waveOpacity: 0.3,
          unit: "PB",
          value: 32,
          total: 64,
        },
      ],
    };
  },
  mounted() {
    this.drawTimingFn();
  },
  beforeDestroy() {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn() {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, this.$requestInterval);
    },
    setData() {
      // 获取实时利用率
      this.queryRealTimeUtilisation();
      // 节点列表
      this.queryNodeIndex();
      // 任务列表
      this.queryTaskIndex();
    },
   
    // 实时利用率
    queryRealTimeUtilisation() {
      const params = {
        cluster: this.cluster,
      };
      getRealTimeUtilisation(params)
        .then((response) => {
          const { cpu, cpuInfo, mem, memInfo, gpu, gpuInfo, df, dfInfo } =
            response.data;
          const updates = [
            { index: 0, data: cpu, value: cpuInfo },
            { index: 1, data: mem, value: memInfo },
            { index: 2, data: gpu, value: gpuInfo },
            { index: 3, data: df, value: dfInfo },
          ];

          updates.forEach(({ index, data, value }) => {
            this.$set(this.waterEchartData, index, {
              ...this.waterEchartData[index],
              data: [data],
              value,
            });
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    },
    // 节点列表
    queryNodeIndex() {
      const params = {
        cluster: this.cluster,
      };
      getNodeIndex(params)
        .then((response) => {
          const list = response.data.list || [];
          const result = list.map((obj) => [
            obj.name,
            obj.state,
            obj.cpu,
            obj.mem,
          ]);

          this.nodeConfig.data = result;
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    },
    // 任务列表
    queryTaskIndex() {
      getTaskIndex(this.cluster)
        .then((response) => {
          const list = response.data.list || [];
          const result = list.map((obj) => [
            obj.jobid,
            obj.jobName,
            obj.user,
            obj.runTime,
          ]);
          this.taskConfig.data = result;
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    },


    JumpDetail() {
      this.$router.push({
        path: "/detail",
        query: {
          title: this.title,
          cluster:this.cluster
        },
      });
    },
  }
  
  ,
};
</script>
  
  <style lang="scss" scoped>
$box-width: 460px;
$box-height: 750px;

#bottomOne {
  padding: 6px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  position: relative;

  .bg-color-black {
    height: $box-height - 12px;
    width: $box-width;
    border-radius: 10px;
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    .left {
      font-family: YouSheBiaoTiHei;
      font-size: 30px;
      color: #eff4ff;
    }
    .right {
      .dv-border-box-13 {
        width: 81px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .right-item {
        background-image: url("../../assets/icon1.png");
        background-size: cover;
        width: 81px;
        height: 31px;
        line-height: 31px;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        color: #ffffff;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
  .line-echart,
  .bar-echart,
  .line-bar-echart {
    position: relative;
    .h2-title {
      position: absolute;
      top: 16px;
      left: 16px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      .play-icon {
        margin-right: 8px;
        position: relative;
        top: -2px;
        width: 0;
        height: 0;
        border-radius: 2px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 9px solid #ffd03b;
        transform: rotate(270deg);
      }
    }
  }

  .water-echart {
    padding: 0 16px;
    ::v-deep .dv-water-pond-level text {
      color: #fff !important;
      font-size: 20px;
      font-weight: 600;
      text-fill-color: #fff;
    }

    .water-list {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        .value {
          margin-top: 4px;
          font-weight: 500;
          font-size: 10px;
          color: #fff;
          word-wrap: break-word;
        }
      }
    }
    .h2-title {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      .play-icon {
        margin-right: 8px;
        position: relative;
        top: -2px;
        width: 0;
        height: 0;
        border-radius: 2px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 9px solid #ffd03b;
        transform: rotate(270deg);
      }
    }
  }

  .scroll-box {
    padding: 0 16px;
    margin-top: 16px;
    // reset 
   ::v-deep .dv-scroll-board .header .header-item {
        font-size: 10px;
        padding:0;
        text-align: center;
    }

    ::v-deep .dv-scroll-board .rows .row-item {
      font-size: 10px;

    }

    ::v-deep .dv-scroll-board .rows .ceil {
      text-align: center;
      &:hover{
        overflow: visible;
        white-space: nowrap;
        color: #fff;
        background-color: #129be6;
        transform: scale(1.2);
        margin: 0;
        padding: 0;
      }
    }
  }
}
</style>
  