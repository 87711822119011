<template>
  <div id="bottomOne">
    <div class="bg-color-black">
      <div class="mask">
        <span>暂未接入</span>
      </div>
      <!-- 头部标题 -->
      <div class="header">
        <div class="left">{{ title }}</div>
        <div class="right d-flex">
          <div class="right-item">集群详情</div>
          <div class="right-item">前往使用</div>
        </div>
      </div>

      <!-- 实时利用率 水波图 -->
      <div class="water-echart">
        <div class="h2-title">
          <div class="play-icon"></div>
          <span>实时使用率</span>
        </div>
        <div class="water-list">
          <div class="item" v-for="item in waterEchartData" :key="item.name">
            <dv-water-level-pond
              ref="water"
              :config="item"
              style="width: 80px; height: 80px"
            />
            <!-- 数值 -->
            <div class="value">
              {{ item.name }} {{ item.value }}{{ item.unit }} / {{ item.total
              }}{{ item.unit }}
            </div>
          </div>
        </div>
      </div>

      <!-- 历史使用率折线图 -->
      <div class="line-echart">
        <div class="h2-title">
          <div class="play-icon"></div>
          <span>历史使用率</span>
        </div>
        <div class="d-flex jc-center">
          <BottomLineChart />
        </div>
      </div>

      <!-- 节点状态 堆叠柱状图 -->
      <div class="bar-echart">
        <div class="h2-title">
          <div class="play-icon"></div>
          <span>节点状态</span>
        </div>
        <div class="d-flex jc-center">
          <BottomBarChart />
        </div>
      </div>

      <!-- 任务状态 折线柱状图 -->
      <div class="line-bar-echart">
        <div class="h2-title">
          <div class="play-icon"></div>
          <span>任务状态</span>
        </div>
        <div class="d-flex jc-center">
          <BottomLineBarChart />
        </div>
      </div>

      <!-- 轮播表 -->
      <div class="scroll-box d-flex jc-between">
        <!-- 任务 -->
        <dv-scroll-board
          :config="taskConfig"
          style="width: 208px; height: 138px"
        />
        <!-- 节点 -->
        <dv-scroll-board
          :config="nodeConfig"
          style="width: 208px; height: 138px"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
// 折线图
import BottomLineChart from "@/components/echart/bottom/bottomLineChart";

// 柱状图
import BottomBarChart from "@/components/echart/bottom/bottomBarChart";

// 折线柱状图
import BottomLineBarChart from "@/components/echart/bottom/bottomLineBarChart";

export default {
  components: {
    BottomLineChart,
    BottomBarChart,
    BottomLineBarChart,
  },

  data() {
    return {
      title: "海洋工程集群",
      taskConfig: {
        header: ["任务ID", "任务名", "用户名"], // 表头title
        data: [
          ["1", "runssks", "user1"],
          ["2", "runssks", "user1"],
          ["3", "runssks", "user1"],
          ["4", "runssks", "user1"],
          ["5", "runssks", "user1"],
          ["56", "runssks", "user1"],
          ["52", "runssks", "user1"],
          ["53", "runssks", "user1"],
        ],
        headerBGC: "rgba(106, 112, 124, 0.20)", //表头颜色
        evenRowBGC: "", //偶数列背景色
        oddRowBGC: "rgba(106, 112, 124, 0.20)", // 基数列背景色
        columnWidth: [69.33, 69.33, 69.33], // 每列宽度
        align: ["center"],
        waitTime: "3000", // 轮播间隔
        rowNum: 4, //展示的行数
      },

      nodeConfig: {
        header: ["节点名称", "状态", "CPU", "MEM"], // 表头title
        data: [
          ["bide-1", "ok", "73%", "76%"],
          ["bide-2", "ok", "73%", "76%"],
          ["bide-3", "ok", "73%", "76%"],
          ["bide-4", "ok", "73%", "76%"],
          ["bide-5", "ok", "73%", "76%"],
          ["bide-6", "ok", "73%", "76%"],
          ["bide-7", "ok", "73%", "76%"],
          ["bide-8", "ok", "73%", "76%"],
        ],
        headerBGC: "rgba(106, 112, 124, 0.20)", //表头颜色
        evenRowBGC: "", //偶数列背景色
        oddRowBGC: "rgba(106, 112, 124, 0.20)", // 基数列背景色
        columnWidth: [68, 46.6, 46.6, 46.6], // 每列宽度
        align: ["center"],
        waitTime: "3000", // 轮播间隔
        rowNum: 4, //展示的行数
      },
      waterEchartData: [
        {
          name: "CPU",
          data: [42],
          shape: "roundRect",
          colors: ["rgba(49, 126, 234, 1)", "rgba(48, 209, 238, 1)"],
          waveNum: 1,
          waveHeight: 10,
          waveOpacity: 0.3,
          unit: "核",
          value: 32,
          total: 64,
        },
        {
          name: "MEM",
          data: [67],
          shape: "roundRect",
          colors: ["rgba(66, 244, 183, 1)", "rgba(48, 220, 232, 1)"],
          waveNum: 1,
          waveHeight: 10,
          waveOpacity: 0.3,
          unit: "GB",
          value: 32,
          total: 64,
        },
        {
          name: "GPU",
          data: [56],
          shape: "roundRect",
          colors: ["rgba(251, 221, 118, 1)", "rgba(252, 145, 90, 1)"],
          waveNum: 1,
          waveHeight: 10,
          waveOpacity: 0.3,
          unit: "卡",
          value: 32,
          total: 64,
        },
        {
          name: "存储",
          data: [42],
          shape: "roundRect",
          colors: ["rgba(218, 34, 255, 1)", "rgba(151, 51, 238, 1)"],
          waveNum: 1,
          waveHeight: 10,
          waveOpacity: 0.3,
          unit: "PB",
          value: 32,
          total: 64,
        },
      ],
    };
  },

  mounted() {
  },
  methods: {},
};
</script>
  
  <style lang="scss" scoped>
$box-width: 460px;
$box-height: 750px;

#bottomOne {
  padding: 6px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  position: relative;

  .bg-color-black {
    height: $box-height - 12px;
    width: $box-width;
    border-radius: 10px;

    position: relative;
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(
        255,
        255,
        255,
        0.2
      ); /* 半透明黑色背景，最后一个参数控制透明度 */
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      z-index: 10000;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    .left {
      font-family: YouSheBiaoTiHei;
      font-size: 30px;
      color: #eff4ff;
    }
    .right {
      .dv-border-box-13 {
        width: 81px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .right-item {
        background-image: url("../../assets/icon1.png");
        background-size: cover;
        width: 81px;
        height: 31px;
        line-height: 31px;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        color: #ffffff;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
  .line-echart,
  .bar-echart,
  .line-bar-echart {
    position: relative;
    .h2-title {
      position: absolute;
      top: 16px;
      left: 16px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      .play-icon {
        margin-right: 8px;
        position: relative;
        top: -2px;
        width: 0;
        height: 0;
        border-radius: 2px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 9px solid #ffd03b;
        transform: rotate(270deg);
      }
    }
  }

  .water-echart {
    padding: 0 16px;
    ::v-deep .dv-water-pond-level text {
      color: #fff !important;
      font-size: 20px;
      font-weight: 600;
      text-fill-color: #fff;
    }

    .water-list {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        .value {
          margin-top: 4px;
          font-weight: 500;
          font-size: 10px;
          color: #fff;
          word-wrap: break-word;
        }
      }
    }
    .h2-title {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      .play-icon {
        margin-right: 8px;
        position: relative;
        top: -2px;
        width: 0;
        height: 0;
        border-radius: 2px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 9px solid #ffd03b;
        transform: rotate(270deg);
      }
    }
  }

  .scroll-box {
    padding: 0 16px;
    margin-top: 16px;
    // reset
    ::v-deep .dv-scroll-board .header .header-item {
      font-size: 10px;
      padding: 0;
      text-align: center;
    }

    ::v-deep .dv-scroll-board .rows .row-item {
      font-size: 10px;
    }

    ::v-deep .dv-scroll-board .rows .ceil {
      text-align: center;
    }
  }
}
</style>
  