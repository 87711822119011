<template>
  <div id="center1">
    <div class="bg-color-black">
      <div class="up">
        <div class="item" v-for="item in dataList" :key="item.id">
          <div class="title">{{ item.title || "未知" }}</div>
          <div class="value d-flex jc-center">
            <span>{{ item.value || 0 }}</span>
            <span class="unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
      <div class="down">
        <dv-capsule-chart :config="config" v-if="configShow" />
        <!-- <CenterChartBar /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getIndexMain } from "@/common/api/index.js";

// import CenterChartBar from "@/components/echart/center/centerChartBar";
export default {
  components: {
    // CenterChartBar,
  },
  data() {
    return {
      configShow: false,
      dataList: [
        {
          title: "CPU",
          value: "",
          unit: "核",
          key: "pending",
        },
        {
          title: "MEM",
          value: "",
          unit: "GB",
          key: "mem",
        },
        {
          title: "GPU",
          value: "",
          unit: "卡",
          key: "gpu",
        },
        {
          title: "存储",
          value: "",
          unit: "PB",
          key: "df",
        },

        {
          title: "近期用户",
          value: "",
          unit: "",
          key: "user",
        },
        {
          title: "近期任务",
          value: "",
          unit: "",
          key: "task",
        },
      ],
      config: {
        data: [
          {
            name: "CPU",
            value: "",
          },
          {
            name: "MEN",
            value: "",
          },
          {
            name: "GPU",
            value: "",
          },
          {
            name: "存储",
            value: "",
          },
          {
            name: "存储",
            value: "100",
          },
        ],
        showValue: true,
        unit: "%",
      },
    };
  },

  mounted() {
    this.drawTimingFn();
  },
  beforeDestroy() {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn() {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, this.$requestInterval);
    },
    setData() {
      // 概览
      this.queryIndexMain();
    },

    queryIndexMain() {
      getIndexMain()
        .then((response) => {
          // 定义一个映射关系，如果dataList的索引和属性名称不一致，可以在这里调整
          const dataListMapping = [
            { index: 0, prop: "pending" },
            { index: 1, prop: "mem" },
            { index: 2, prop: "gpu" },
            { index: 3, prop: "df" },
            { index: 4, prop: "user" },
            { index: 5, prop: "task" },
          ];

          // 使用映射和循环来更新this.dataList的值
          dataListMapping.forEach((item) => {
            if (item.prop in response.data) {
              this.dataList[item.index].value = response.data[item.prop];
            }
          });

          const configMapping = [
            { index: 0, prop: "cpu_utilisation" },
            { index: 1, prop: "mem_utilisation" },
            { index: 2, prop: "gpu_utilisation" },
            { index: 3, prop: "df_utilisation" },
          ];
          // 使用映射和循环来更新this.dataList的值
          configMapping.forEach((item) => {
            if (item.prop in response.data) {
              this.config.data[item.index].value = response.data[item.prop];
            }
          });

          this.configShow = true;

        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#center1 {
  display: flex;
  flex-direction: column;
  height: 198px;
  width: 100%;
  box-sizing: border-box;
  .bg-color-black {
    border-radius: 10px;
    height: calc(100% - 12px);
    background-color: #0d2647;
    margin: 6px;
  }
  .up {
    padding: 16px 16px 0 16px;
    display: flex;
    align-items: center;
    width: 100%;
    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      border: 4px solid #129be6;
      border-radius: 4px;
      color: #fff;
      background-color: #125497;
      // height: 60px;
      padding: 3px;
      box-shadow: inset 0px -4px 12px 0px rgba(24, 144, 255, 0.81);
      .title {
        font-family: Arial, Helvetica, sans-serif;

        // font-family: PingFang SC, PingFang SC;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 2px;
        font-style: normal;
      }
      .value {
        font-family: Arial, Helvetica, sans-serif;
        // font-family: PingFang SC, PingFang SC;
        width: 100%;
        font-size: 26px;
        font-weight: 500;
      }
      .unit {
        font-family: Arial, Helvetica, sans-serif;
        margin-left: 3px;
        font-size: 12px;
      }
    }
    .item:last-child {
      margin-right: 0;
    }
  }
  .down {
    .dv-capsule-chart {
      padding: 10px 16px 0 16px;

      ::v-deep .label-column > div:nth-child(5) {
        display: none;
      }

      ::v-deep .capsule-container > .capsule-item:nth-child(5) {
        display: none;
      }
    }
  }
}
</style>
