<template>
  <div class="d-flex jc-center">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
import {getGlobalState} from '@/common/api'

export default {
  data () {
    return {
      drawTiming: null,
      cdata: {
       x:[],
       running:[],
       pending:[],
       user:[],
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
    this.drawTimingFn();
  },
  beforeDestroy () {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn () {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, this.$requestInterval);
    },
    setData () {
  
      getGlobalState()
        .then((response) => {
          const { x, pending, running, user } = response.data;
          this.cdata.x = x;
          this.cdata.running = running;
          this.cdata.pending = pending;
          this.cdata.user = user;
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>