<template>
  <div class="d-flex jc-center">
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
import { getTakeState } from "@/common/api/index.js";

export default {
  data() {
    return {
      drawTiming: null,
      cdata: {
        x:[],
        user:[],
        running:[],
        pending:[]
      },
    };
  },
  components: {
    Chart,
  },
  props: {
    cluster: {
      type: String,
      default: () => "",
    },
  },
  mounted() {
    this.drawTimingFn();
  },
  beforeDestroy() {
    clearInterval(this.drawTiming);
  },
  methods: {
    drawTimingFn() {
      this.setData();
      this.drawTiming = setInterval(() => {
        this.setData();
      }, this.$requestInterval);
    },
    setData() {
      // 获取任务状态
      const params = {
        cluster: this.cluster,
      };
      getTakeState(params)
        .then((response) => {
          const { x, user, running, pending } = response.data; 
          this.cdata.x = x;
          this.cdata.user = user;
          this.cdata.running = running;
          this.cdata.pending = pending;
        
        })
        .catch((error) => {
          console.error("There was an error!", error);
          // 处理错误
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>