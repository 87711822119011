<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="title ">
        <div class="play-icon"></div>
        <span>整体任务状态</span>
      </div>
      <div class="d-flex jc-center">
        <centerRightChart />
      </div>
    </div>
  </div>
</template>

<script>
import centerRightChart from "@/components/echart/centerRight/centerRightChart";
export default {
  data() {
    return {};
  },
  components: {
    centerRightChart,
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
$box-width: 460px;
$box-height: 198px;

#centerLeft1 {
  padding: 6px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .bg-color-black {
    height: $box-height - 12px;
    width: $box-width;
    border-radius: 10px;
  }
 
  .title {
    position: absolute;
    top: 16px;
    left: 16px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    .play-icon {
      margin-right: 8px;
      position: relative;
      top: -2px;
      width: 0;
      height: 0;
      border-radius: 2px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 9px solid #ffd03b;
      transform: rotate(270deg);
    }
  }
}
</style>
