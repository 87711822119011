<template>
  <div>
    <Echart
      :options="options"
      id="bottomLineChart"
      height="144px"
      width="460px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
      // 定义颜色
      colorList: [
        {
          itemStyle: "#317EEA",
          linearBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(49, 126, 234, 1)",
              },
              {
                offset: 1,
                color: "rgba(48, 209, 238, 1)",
              },
            ],
          },
        },
        {
          itemStyle: "#42F4B7",
          linearBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "rgba(66, 244, 183, 1)",
              },
              {
                offset: 1,
                color: "rgba(66, 244, 183, 1)",
              },
            ],
          },
        },
        {
          itemStyle: "#FBDD76",
          linearBtoG: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 1,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: "rgba(251, 221, 118, 1)",
              },
              {
                offset: 1,
                color: "rgba(252, 145, 90, 1)",
              },
            ],
          },
        },
      ],
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },

          grid: {
            left: 16,
            right: 16,
            bottom: 0,
            top: 50,
            containLabel: true,
          },
          // toolbox: {
          //   feature: {
          //     dataView: { show: true, readOnly: false },
          //     magicType: { show: true, type: ["line", "bar"] },
          //     restore: { show: true },
          //     saveAsImage: { show: true },
          //   },
          // },
          legend: {
            data: ["运行任务", "排队任务", "用户数量"],
            right: 10,
            top: 16,
            textStyle: {
              fontSize: 12,
              color: (params) => {
                // 假设你的series数组中存储了颜色信息
                const series = this.options.series.find(
                  (s) => s.name === params.name
                );
                return series ? series.linearBtoG.color : "red";
              },
            },

            icon: "roundRect", // 图例icon
            itemGap: 16,
            itemWidth: 8,
            itemHeight: 3,
          },
          xAxis: [
            {
              type: "category",
              axisPointer: {
                type: "shadow",
              },
              axisLine: {
                lineStyle: {
                  opacity: 0,
                },
              },

              axisLabel: {
                color: "#fff",
                fontSize: 12,
                // interval: 200, // 每隔3个类目显示一个标签
                // fontWeight: 600,
                formatter: function (params) {
                  return params.substring(10, 16);
                },
              },
              data: this.CalculateAveragePoints(newData.x),
            },
          ],
          yAxis: [
            {
              type: "value",
              // name: "排队任务",
              // min: 0,
              // max: 100,
              // interval: 50,
              axisLabel: {
                formatter: "{value}",
                textStyle: {
                  color: "#fff", // 设置字体颜色
                  fontSize: 12, // 设置字体大小
                  // fontFamily: "微软雅黑", // 设置字体类型
                },
              },
              axisLine: {
                lineStyle: {
                  opacity: 0,
                },
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: "#1C3452",
                },
              },
            },
            {
              type: "value",
              // name: "用户数量",
              // min: 0,
              // max: 1000,
              // interval: 250,
              // splitNumber: 4,

              axisLabel: {
                textStyle: {
                  color: "rgba(252, 145, 90, 1)",
                  fontSize: 12, // 设置字体大小
                  // fontFamily: "微软雅黑", // 设置字体类型
                },

                formatter: "{value}",
              },
              axisLine: {
                lineStyle: {
                  opacity: 0,
                },
              },
            },
          ],
          series: [
            {
              name: "运行任务",
              type: "bar",
              tooltip: {
                valueFormatter: function (value) {
                  return value + " %";
                },
              },
              barWidth: 10,
              data: newData.running,
              itemStyle: {
                normal: {
                  color: this.colorList[0].linearBtoG,
                },
              },
            },
            {
              name: "排队任务",
              type: "bar",
              tooltip: {
                valueFormatter: function (value) {
                  return value + " %";
                },
              },
              barWidth: 10,
              data: newData.pending,
              itemStyle: {
                normal: {
                  color: this.colorList[1].linearBtoG,
                },
              },
            },
            {
              name: "用户数量",
              type: "line",
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                },
              },
              data: newData.user,
              itemStyle: {
                normal: {
                  color: this.colorList[2].linearBtoG,
                },
              },
            },
          ],
        };
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
