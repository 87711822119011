<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>

      <!-- 大屏内容 -->
      <div class="host-body">
        <!-- style header1 -->
        <div class="header">
          <span class="title-text">珠海校区集群监控大屏</span>
          <span class="now-time"
            >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
          >
        </div>
        <!-- style header2 -->
        <!-- <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text">珠海校区集群监控大屏</span>
              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="decorationColor"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div> -->

        <div class="body-box">
          <!-- 第一行数据 -->
          <div class="content-box">
            <!-- 左边 -->
            <dv-border-box-12>
              <centerLeft />
            </dv-border-box-12>
            <!-- 中间 -->
            <dv-border-box-12>
              <center1 />
            </dv-border-box-12>
            <!-- 右边 -->
            <dv-border-box-12>
              <centerRight />
            </dv-border-box-12>
            <!-- <div>
              <dv-border-box-12>
                <centerLeft2 />
              </dv-border-box-12>
            </div> -->
            <!-- 中间 -->
            <!-- <dv-border-box-12>
              <center />
            </dv-border-box-12> -->

            <!-- 中间 -->
            <!-- <div>
              <centerRight2 />
            </div> -->
          </div>

          <!-- 第二行数据 -->
          <div class="bottom-box">
            <!-- 人工智能集群 -->
            <dv-border-box-12>
              <bottomOne />
            </dv-border-box-12>

            <!-- 海洋工程集群 -->
            <dv-border-box-12>
              <bottomTwo />
            </dv-border-box-12>

            <!-- 大气科学集群 -->
            <dv-border-box-12>
              <bottomThree />
            </dv-border-box-12>

            <!-- 零散算力集群 -->
            <dv-border-box-12>
              <bottomFour />
            </dv-border-box-12>

            <!-- <dv-border-box-12>
              <bottomLeft />
            </dv-border-box-12>
            <dv-border-box-12>
              <bottomRight />
            </dv-border-box-12> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";
import { formatTime } from "../../utils/index.js";
import centerLeft from "./centerLeft";
import center1 from "./center1";
import centerRight from "./centerRight";

import bottomOne from "./bottomOne.vue";
import bottomTwo from "./bottomTwo.vue";
import bottomThree from "./bottomThree.vue";
import bottomFour from "./bottomFour.vue";

// import centerLeft2 from "./centerLeft2";
// import centerRight2 from "./centerRight2";
// import center from "./center";

// import bottomLeft from "./bottomLeft";
// import bottomRight from "./bottomRight";

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      decorationColor: ["#568aea", "#000000"],
    };
  },
  components: {
    centerLeft,
    center1,
    centerRight,
    bottomOne,
    bottomTwo,
    bottomThree,
    bottomFour,
    // centerLeft2,
    // centerRight1,
    // centerRight2,
    // center,
    // bottomLeft,
    // bottomRight,
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    //    计算时间
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/index.scss";
</style>
